import React, { useState } from "react";
import "./AdminPanel.css";
import axios from "axios";
import DatePicker from "react-datepicker";
import "../../Login/datePicker.css";
import { format } from "date-fns";

function AdminPanel() {
  const [formData, setFormData] = useState({
    question: "",
    categoryOptions: ["Müzik", "Sinema", "Futbol", "Oyun"],
    category: "",
    answer1: "",
    answer2: "",
    answer3: "",
    answer4: "",
    answer5: "",
    answer6: "",
    answer7: "",
    answer8: "",
    answer9: "",
    answer10: "",
    autocompleteOptions: [
      "footballClubs",
      "footballPlayers",
      "gameConsole",
      "games",
      "intActors",
      "intBillboardSongs",
      "localMusicians",
      "localRecords",
      "localTSM",
      "nationalTeams",
      "spotify100",
      "intMovies",
      "intDirectors",
      "intMusicians",
      "intRecords",
      "lisChars",
      "localMovies",
      "stardewItems",
    ],
    autocomplete_list: "",
    date: null,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "date") {
      setFormData((prevFormData) => ({
        ...prevFormData,
        date: value ? new Date(value).toISOString().split("T")[0] : null,
      }));
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }
  };

  const handleDateChange = (date) => {
    console.log(date);
    date = format(date, "yyyy-MM-dd");
    setFormData((prevFormData) => ({
      ...prevFormData,
      date: date,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(formData);
    try {
      const response = await axios.post(
        "https://kuiz.fun/api/dataEntry.php",
        formData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      console.log("Veriler işlendi!", response.data);
      setFormData({
        question: "",
        answer1: "",
        answer2: "",
        answer3: "",
        answer4: "",
        answer5: "",
        answer6: "",
        answer7: "",
        answer8: "",
        answer9: "",
        answer10: "",
        category: "",
        autocomplete_list: "",
        date: null,
      });
    } catch (error) {
      console.log("Veriler işlenirken bir sorun oluştu!", error);
    }
  };

  return (
    <div className="panel-container">
      <div className="question-section">
        <form onSubmit={handleSubmit} className="db-form">
          <label>
            <span>Soru:</span>
            <input
              type="text"
              name="question"
              value={formData.question}
              onChange={handleChange}
            />
          </label>
          {[...Array(10)].map((_, index) => (
            <label key={index + 1}>
              <span>Cevap{index + 1}:</span>
              <input
                type="text"
                name={`answer${index + 1}`}
                value={formData[`answer${index + 1}`]}
                onChange={handleChange}
              />
            </label>
          ))}
          <label>
            <span>Kategori:</span>
            <select
              name="category"
              value={formData.category}
              onChange={handleChange}
            >
              <option value="" disabled hidden>
                Kategori seçin
              </option>
              <option value="Muzik">Muzik</option>
              <option value="Sinema">Sinema</option>
              <option value="Futbol">Futbol</option>
              <option value="Oyun">Oyun</option>
            </select>
          </label>

          <label>
            <span>Autocomplete:</span>
            <select
              name="autocomplete_list"
              value={formData.autocomplete_list}
              onChange={handleChange}
            >
              <option value="" disabled hidden>
                Liste seçin
              </option>
              <option value="footballClubs">footballClubs</option>
              <option value="footballPlayers">footballPlayers</option>
              <option value="gameConsole">gameConsole</option>
              <option value="games">games</option>
              <option value="intActors">intActors</option>
              <option value="intMovies">intMovies</option>
              <option value="intDirectors">intDirectors</option>
              <option value="intMusicians">intMusicians</option>
              <option value="intRecords">intRecords</option>
              <option value="lisChars">lisChars</option>
              <option value="localMovies">localMovies</option>
              <option value="stardewItems">stardewItems</option>
              <option value="spotify100">spotify100</option>
              <option value="localRecords">localRecords</option>
              <option value="nationalTeams">nationalTeams</option>
              <option value="localTSM">localTSM</option>
              <option value="localMusicians">localMusicians</option>
              <option value="intBillboardSongs">intBillboardSongs</option>
            </select>
          </label>
          <label>
            <span>Tarih:</span>
            <DatePicker
              selected={formData.date}
              onChange={handleDateChange}
              minDate={new Date()}
              dateFormat="yyyy-MM-dd"
            />
          </label>
          <button type="submit">Gönder</button>
        </form>
      </div>
    </div>
  );
}

export default AdminPanel;
