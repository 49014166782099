import React from "react";
import "./MobileNavbar.css";

const MobileNavbar = ({ isOpen, toggleMenu }) => {
  return (
    <>
      <div
        className={`mobile-menu ${isOpen ? "active" : ""}`}
        onClick={toggleMenu}
      >
        <div className="mobile-menu-container">
          <a className="logo-item" href="/">
            <img
              className="mobile-logo"
              href="anasayfa"
              src="/assets/images/logoKuiz.png"
              alt="anasayfa"
            />
          </a>
          <ul>
            <li>
              <a className="menu-item" href="muzik">
                <img src="/assets/images/earphone.svg" alt="music" />
                Müzik
              </a>
            </li>
            <li>
              <a className="menu-item" href="sinema">
                <img src="/assets/images/movie1.svg" alt="sinema" />
                Sinema
              </a>
            </li>
            <li>
              <a className="menu-item" href="futbol">
                <img src="/assets/images/trophy.svg" alt="futbol" />
                Futbol
              </a>
            </li>
            <li>
              <a className="menu-item" href="oyun">
                <img src="/assets/images/gamepad2.svg" alt="oyun" />
                Oyun
              </a>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default MobileNavbar;
